import { FC, MouseEvent } from "react";
import styles from "./listingGridItem.module.scss";
import { BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import classNames from "classnames";

type Props = {
  flightName: string;
  flightType: string;
  flightDate: { [key: string]: string };
  imageId?: string;
  flightId: string;
  flightIsForm: boolean;
  onDeleteFlight: (
    e: MouseEvent<SVGElement>,
    flightId: string,
    flightIsForm: boolean
  ) => void;
};

enum EFlightType {
  round = "Round trip",
  multi = "Multi-city",
  single = "One-way",
}

export const ListingGridItem: FC<Props> = ({
  flightName,
  flightType,
  flightDate,
  imageId,
  flightId,
  flightIsForm,
  onDeleteFlight,
}) => {
  const flightTypeClass = classNames({
    [styles.chip]: true,
    [styles.chipMultiFlightType]: flightType === EFlightType.multi,
    [styles.chipRoundFlightType]: flightType === EFlightType.round,
    [styles.chipSingleFlightType]: flightType === EFlightType.single,
  });

  return (
    <>
      <Link to={flightId} className={styles.gridItemContainer}>
        <div className={styles.gridItem}>
          <div className={styles.imageContainer}>
            <div className={flightTypeClass}>
              <p>{flightType}</p>
            </div>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url("/assets/flight/${imageId}.jpg")`,
              }}
            />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.title} title={"title"}>
              {flightName ? flightName : `Flight #${flightId}`}
            </h2>
            <div className={styles.date}>
              <div className={styles.iconDepartFlight}></div>
              <p>
                {flightDate?.startFlightDate} {flightDate?.startFlightTime}
              </p>
              <div className={styles.iconReturnFlight}></div>

              <p>
                {flightDate?.endFlightDate} {flightDate?.endFlightTime}
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.btnMenu}>
              <span className={styles.btn}>
                <BsTrash
                  onClick={(e) => onDeleteFlight(e, flightId, flightIsForm)}
                  className={styles.trashIcon}
                />
              </span>
            </div>
          </div>
        </div>
      </Link>
      {/* <Modal>
        <RemoveFlightBlock onClose={onCloseRemoveFlightModal} />
      </Modal> */}
    </>
  );
};
