import { ECollectionName } from "./../../../types";
import { auth, db, functions } from "@app/configs/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  signOut,
  User,
  updateProfile,
  updateEmail,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
const provider = new GoogleAuthProvider();
const createPMTToken = httpsCallable(functions, "createPMTToken");

export const createFirebaseAuthMiddleware = (): Middleware => {
  return (storeAPI) => {
    onAuthStateChanged(auth, (user) => {
      isLoading(storeAPI, true);
      if (user) {
        localStorage.setItem("uid", user.uid);
        signInUser(storeAPI, user);

        storeAPI.dispatch({ type: "auth/SET_USER" });
        const uid = user.uid;

        createPMTToken({ uid }).then((res: any) => {
          window.postMessage({ type: "signInWeb", data: res.data.token });
        });
        isLoading(storeAPI, false);
      } else {
        localStorage.removeItem("uid");
        isLoading(storeAPI, false);
        signOutUser(storeAPI);
        window.postMessage({ type: "signOutWeb" });
      }
    });
    return (next) => async (action) => {
      if (action.type === "auth/SIGN_IN_GOOGLE") {
        isLoading(storeAPI, true);
        signInWithPopup(auth, provider)
          .then(console.log)
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(errorCode, errorMessage, email, credential);
          });
        isLoading(storeAPI, false);
        return;
      }
      if (action.type === "auth/SEND_SIGN_IN_LINK") {
        isLoading(storeAPI, true);

        const actionCodeSettings = {
          url: "https://app.planmoretrips.com/login",
          handleCodeInApp: true,
        };
        sendSignInLinkToEmail(auth, action.payload, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem("emailForSignIn", action.payload);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
          });
        isLoading(storeAPI, false);

        return;
      }
      if (action.type === "auth/SIGN_IN_WITH_LINK") {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");
          if (!email) {
            email = window.prompt("Please provide your email for confirmation");
          }
          signInWithEmailLink(auth, email!, window.location.href)
            .then((result) => {
              window.localStorage.removeItem("emailForSignIn");
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode, errorMessage);
            });
          return;
        }
      }
      if (action.type === "auth/SIGN_OUT") {
        signOut(auth).then(console.log).catch(console.log);
        return;
      }
      if (action.type === "auth/CHANGE_USER_NAME") {
        const user = auth.currentUser;
        if (user) {
          isLoading(storeAPI, true);

          updateProfile(user, {
            displayName: action.payload,
          })
            .then(() => {
              storeAPI.dispatch({
                type: "user/changeDisplayName",
                payload: user.displayName,
              });
              storeAPI.dispatch({
                type: "auth/UPDATE_USER",
              });
              isLoading(storeAPI, false);
            })
            .catch(console.log);
        }
      }
      if (action.type === "auth/CHANGE_USER_PHOTO") {
        const user = auth.currentUser;
        if (user) {
          isLoading(storeAPI, true);
          const storage = getStorage();
          const storageRef = ref(storage, `avatars/${user?.uid}`);
          uploadBytes(storageRef, action.payload).then((snapshot) => {
            getDownloadURL(ref(storage, `avatars/${user?.uid}`)).then((url) => {
              updateProfile(user, {
                photoURL: url,
              })
                .then(() => {
                  storeAPI.dispatch({
                    type: "user/changePhotoURL",
                    payload: user.photoURL,
                  });
                  storeAPI.dispatch({
                    type: "auth/UPDATE_USER",
                  });
                  isLoading(storeAPI, false);
                })
                .catch(console.log);
            });
          });
        }
      }

      if (action.type === "auth/CHANGE_USER_EMAIL") {
        const user = auth.currentUser;
        if (user) {
          isLoading(storeAPI, true);
          updateEmail(user, action.payload)
            .then(() => {
              storeAPI.dispatch({
                type: "user/changeEmail",
                payload: user.email,
              });
              storeAPI.dispatch({
                type: "auth/UPDATE_USER",
              });
              isLoading(storeAPI, false);
            })
            .catch(() => {
              storeAPI.dispatch({ type: "auth/SIGN_IN_GOOGLE" });
              updateEmail(user, action.payload).then(() => {
                storeAPI.dispatch({
                  type: "user/changeEmail",
                  payload: user.email,
                });
                storeAPI.dispatch({
                  type: "auth/UPDATE_USER",
                });
                isLoading(storeAPI, false);
              });
            });
        }
      }

      if (action.type === "auth/SET_USER") {
        const user = auth.currentUser;
        if (user) {
          isLoading(storeAPI, true);

          const docRef = doc(db, ECollectionName.users, user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            storeAPI.dispatch({
              type: "user/addCommentsChannel",
              payload: docSnap.data().commentsChannel,
            });
            isLoading(storeAPI, false);
          } else {
            const { displayName, email, uid, photoURL } = user;
            const commentsChannel =
              storeAPI.getState().user.data.commentsChannel;

            await setDoc(
              doc(db, ECollectionName.users, user.uid),
              {
                displayName,
                email,
                uid,
                photoURL,
                commentsChannel,
              },
              { merge: true }
            );
            isLoading(storeAPI, false);
          }
        }
      }

      if (action.type === "auth/UPDATE_USER") {
        const user = auth.currentUser;
        if (user) {
          const commentsChannel = storeAPI.getState().user.data.commentsChannel;
          const { displayName, email, uid, photoURL } = user;
          await setDoc(
            doc(db, ECollectionName.users, user.uid),
            {
              displayName,
              email,
              uid,
              photoURL,
              commentsChannel,
            },
            { merge: true }
          );
        }
      }

      return next(action);
    };
  };
};

const isLoading = (
  storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>,
  value: boolean
) => {
  storeAPI.dispatch({
    type: "user/isLoading",
    payload: value,
  });
};

interface InitialUserData {
  email: string;
  uid: string;
  displayName: string;
  photoURL: string;
}

const initialUserData = {
  email: "",
  uid: "",
  displayName: "",
  photoURL: "",
};

const saveUserData = (
  storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>,
  user: User | InitialUserData
) => {
  try {
    storeAPI.dispatch({
      type: "user/changeEmail",
      payload: user.email,
    });
    storeAPI.dispatch({
      type: "user/changeDisplayName",
      payload: user.displayName,
    });
    storeAPI.dispatch({ type: "user/changeUid", payload: user.uid });
    storeAPI.dispatch({
      type: "user/changePhotoURL",
      payload: user.photoURL,
    });
  } catch (error) {
    storeAPI.dispatch({ type: "user/error", payload: true });
  }
};

const signInUser = (
  storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>,
  user: User
) => {
  saveUserData(storeAPI, user);
};
const signOutUser = (storeAPI: MiddlewareAPI<Dispatch<AnyAction>, any>) => {
  saveUserData(storeAPI, initialUserData);
};
