import { FC } from "react";
import ReactDOM from "react-dom";
import styles from "./loadBar.module.scss";

type Props = {};

export const LoadBar: FC<Props> = (props) => {
  return ReactDOM.createPortal(
    <>
      <div className={styles.loadBar}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
      <div data-type="loader" className={styles.loadBlock} />
    </>,
    document.body
  );
};
