import { useGetSnapCollectionQuery } from "@app/api/firebase";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ECollectionName } from "../../types";
import styles from "./flightSegment.module.scss";

type Props = {
  segmentId: number;
  partId: number;
};

export const FlightSegment: FC<Props> = ({ partId, segmentId }) => {
  const { flight } = useParams<{ user: string; flight: string }>();

  const { segmentData } = useGetSnapCollectionQuery(
    {
      path: `${ECollectionName.flights}.${flight}`,
    },
    {
      selectFromResult: ({ data, error, isLoading }) => {
        return {
          segmentData:
            data?.[ECollectionName.flights]?.[flight!].parts[partId].segments[
              segmentId
            ],
          error,
          isLoading,
        };
      },
    }
  );
  return (
    <div className={styles.segmentWrapper}>
      <div className={styles.segmentContainer}>
        <div className={styles.segmentContent}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url("https://content.airhex.com/content/logos/airlines_${segmentData.airlineCode}_70_70_s.png")`,
            }}
          ></div>
          <div
            className={styles.divider}
            style={{
              backgroundImage:
                'url("https://www.gstatic.com/flights/app/1x/dotted_flight_80dp.png")',
            }}
          ></div>
          <div className={styles.content}>
            <div className={styles.title}>
              {segmentData.departTime}
              {segmentData.departExtraDays >= 1 && (
                <span className={styles.offset}>
                  +{segmentData.departExtraDays}
                </span>
              )}{" "}
              · {segmentData.departAirportName} (
              {segmentData.departAirportShort})
            </div>
            <div className={styles.time}>
              Travel time: {segmentData.travelTime}
            </div>
            <div className={styles.title}>
              {segmentData.arrivalTime}
              {segmentData.arrivalExtraDays >= 1 && (
                <span className={styles.offset}>
                  +{segmentData.arrivalExtraDays}
                </span>
              )}{" "}
              · {segmentData.arrivalAirportName} (
              {segmentData.arrivalAirportShort})
            </div>
          </div>
        </div>
        <div className={styles.info}>
          {segmentData.airlineName} ·{" "}
          {segmentData.serviceClassPlane
            ? segmentData.serviceClassPlane
            : "Economy"}{" "}
          · {segmentData.planeType} · {segmentData.airlineCode}{" "}
          {segmentData.flightNumber}
        </div>
      </div>
    </div>
  );
};
