import { Header } from "@components/Header";
import { FC, useEffect, useState } from "react";
import styles from "./flight.module.scss";
import { Button } from "@components/Button";
import { RiShareBoxFill } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineTemplate } from "react-icons/hi";
import { Comments } from "@components/Comments";
import { FlightOption } from "@components/FlightOption";
import { PriceSearchBlock } from "@components/PriceSearchBlock";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkStatusBookingOption,
  useAddSnapMutation,
  useGetSnapCollectionQuery,
} from "@app/api/firebase";
import {
  ECollectionName,
  EMenuTabType,
  EMobileTabType,
  EStatusSearchPrice,
  IBookingOption,
} from "../../types";
import { Link } from "react-router-dom";
import { Modal } from "@components/Modal";
import { ShareBlock } from "@components/ShareBlock";
import { LoadBar } from "@components/LoadBar";
import { useAppSelector } from "@app/store/hooks";

type Props = {};

export const Flight: FC<Props> = (props) => {
  const { flight: flightId } = useParams<{
    user: string;
    flight: string;
  }>();
  const {
    data: { uid: userId },
  } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const [changeParamsDb] = useAddSnapMutation();
  const {
    dataFlight,
    isLoading: isLoadingQuery,
    isSuccess: isSuccessQuery,
  } = useGetSnapCollectionQuery(
    {
      path: `${ECollectionName.flights}.${flightId}`,
    },
    {
      selectFromResult: ({ data, error, isLoading, isSuccess }) => {
        return {
          dataFlight: data?.[ECollectionName.flights]?.[flightId!],
          error,
          isLoading,
          isSuccess,
        };
      },
    }
  );

  useEffect(() => {
    if (userId && !dataFlight && isSuccessQuery) {
      navigate(`/${userId}/flights`);
    }
  }, [userId, dataFlight, isSuccessQuery, navigate]);

  const [mobileTab, setMobileTab] = useState<EMobileTabType>(
    EMobileTabType.gallery
  );
  const [menuTab, setMenuTab] = useState<EMenuTabType>(EMenuTabType.flight);
  const [isOpenShareBlock, setIsOpenShareBlock] = useState<boolean>(false);

  useEffect(() => {
    if (dataFlight) {
      checkStatusBookingOption(dataFlight, flightId!, changeParamsDb);
    }
  }, [dataFlight, flightId, changeParamsDb]);

  const handleToggleShareBlock = () => {
    setIsOpenShareBlock((isOpenShareBlock) => !isOpenShareBlock);
  };

  const handleChangeMobileTabItem = (tab: EMobileTabType) => setMobileTab(tab);

  const handleChangeMainTabItem = (tab: EMenuTabType) => setMenuTab(tab);

  const [isOpenSearchBlock, setIsOpenSearchBlock] = useState<boolean>(true);

  const handleCloseSearchBlock = () => {
    setIsOpenSearchBlock(false);
    changeParamsDb({
      type: "change",
      path: `${ECollectionName.flights}.${flightId}`,
      data: { statusSearchPrice: EStatusSearchPrice.read },
    });
  };

  const mergeHashWithUrl = (hash: string, url: string): string => {
    return `${url}${hash}`;
  };

  const createHash = (label: string): string => {
    return `#${label}`;
  };

  const isOpenBookingOption =
    dataFlight?.statusSearchPrice === EStatusSearchPrice.success ||
    dataFlight?.statusSearchPrice === EStatusSearchPrice.read;

  return (
    <>
      {isLoadingQuery && <LoadBar />}
      <Header />
      {dataFlight && (
        <div className={styles.flightWrapper}>
          <div className={styles.flightContainer}>
            <div
              className={`${styles.scrollWrapper} ${
                mobileTab === EMobileTabType.message && styles.hideFlight
              }`}
            >
              <div className={styles.flightHeader}>
                <Link to={`/${userId}\\flights`}>
                  <div className={styles.backBtn}>
                    <IoIosArrowBack />
                  </div>
                </Link>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url("/assets/flight/${dataFlight.imageId}.jpg")`,
                  }}
                >
                  <h1 className={styles.headline}>{dataFlight.flightName}</h1>
                </div>
                <div className={styles.headerMenu}>
                  <div className={styles.tabGroup}>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.flight)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.flight && styles.activeTab
                      }`}
                    >
                      Flights
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.lodging)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.lodging && styles.activeTab
                      }`}
                    >
                      Lodging
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.transport)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.transport && styles.activeTab
                      }`}
                    >
                      Transport
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.activities)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.activities && styles.activeTab
                      }`}
                    >
                      Activities
                    </span>
                  </div>
                  <div className={styles.btnGroup}>
                    <Button onClick={handleToggleShareBlock}>
                      {" "}
                      <RiShareBoxFill />
                      Share Flight
                    </Button>
                  </div>
                </div>
              </div>

              {menuTab !== EMenuTabType.flight && (
                <div className={styles.inDevelopingWrapper}>
                  <div>
                    This feature is currently under development. It will be
                    available soon.
                  </div>
                </div>
              )}

              {menuTab === EMenuTabType.flight && (
                <>
                  {dataFlight.statusSearchPrice &&
                    isOpenSearchBlock &&
                    dataFlight.statusSearchPrice !==
                      EStatusSearchPrice.read && (
                      <div
                        className={`${styles.priceSearchBlockWrapper} ${
                          dataFlight.statusSearchPrice ===
                          EStatusSearchPrice.pending
                            ? styles.pending
                            : styles.success
                        }`}
                      >
                        {dataFlight.statusSearchPrice ===
                          EStatusSearchPrice.success && (
                          <button
                            onClick={handleCloseSearchBlock}
                            className={styles.close}
                          />
                        )}
                        <PriceSearchBlock
                          status={dataFlight.statusSearchPrice}
                        />
                      </div>
                    )}

                  {isOpenBookingOption && (
                    <>
                      <h2>Lowest Prices For Your Flight</h2>
                      {dataFlight.bookingOption ? (
                        dataFlight.bookingOption.map(
                          (option: IBookingOption, idx: number) => (
                            <div
                              key={idx}
                              className={styles.lowestPricesContainer}
                            >
                              <a
                                target={"_blank"}
                                href={mergeHashWithUrl(
                                  createHash(option.labelFlightCompany),
                                  option.url
                                )}
                                rel="noreferrer"
                              >
                                <div className={styles.lowestPricesItem}>
                                  <span className={styles.lowestPricesTitle}>
                                    Book with {option.labelFlightCompany}
                                  </span>
                                  <Button>{option.price}</Button>
                                </div>
                              </a>
                            </div>
                          )
                        )
                      ) : (
                        <div className={styles.lowestPricesContainer}>
                          <div className={styles.lowestPricesItem}>
                            Price not found
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {menuTab === EMenuTabType.flight && (
                    <>
                      <div className={styles.headlineMain}>
                        <div className={styles.optionMenu}>
                          <h2>Your Saved Flight</h2>
                          {dataFlight.url && (
                            <a
                              target="_blank"
                              href={dataFlight.url}
                              rel="noreferrer"
                            >
                              <RiShareBoxFill />
                            </a>
                          )}
                        </div>
                      </div>
                      <div className={styles.flightMain}>
                        <FlightOption />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div
              className={`${styles.commentsContainer} ${
                mobileTab === EMobileTabType.message && styles.showMessage
              }`}
            >
              <Comments />
            </div>
            <div className={styles.mobileNavigation}>
              <span
                onClick={() =>
                  handleChangeMobileTabItem(EMobileTabType.gallery)
                }
                className={`${styles.tabItem} ${
                  mobileTab === EMobileTabType.gallery && styles.activeTab
                }`}
              >
                <span>
                  <HiOutlineTemplate />
                </span>
              </span>
              <span
                onClick={() =>
                  handleChangeMobileTabItem(EMobileTabType.message)
                }
                className={`${styles.tabItem} ${
                  mobileTab === EMobileTabType.message && styles.activeTab
                }`}
              >
                <span>
                  <AiOutlineMessage />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}{" "}
      {isOpenShareBlock && (
        <Modal
          minHeight={240}
          maxWidth={530}
          onCloseModal={handleToggleShareBlock}
        >
          <ShareBlock onClose={handleToggleShareBlock} />
        </Modal>
      )}
    </>
  );
};
