import { Button } from "@components/Button";
import { FC, FormEvent, MouseEvent } from "react";
import styles from "./addFlightForm.module.scss";

type Props = {
  onCloseModal?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmitNewFlight?: (e: FormEvent<HTMLFormElement>) => void;
};

export const AddFlightForm: FC<Props> = ({
  onCloseModal,
  onSubmitNewFlight,
}) => {
  return (
    <div className={styles.addForm}>
      <h2 className={styles.label}>add new flight</h2>
      <form action="" onSubmit={onSubmitNewFlight}>
        <label htmlFor="date">departure date</label>
        <input type="date" id="date" name="date" required />

        <label htmlFor="airline-code">airline code</label>
        <input type="input" name="airlineCode" placeholder="ie. AA" required />

        <label htmlFor="flight-number">flight number</label>
        <input type="input" name="flightNumber" placeholder="ie. 78" required />

        <label htmlFor="price">price</label>
        <input
          type="number"
          min="0"
          name="price"
          placeholder="ie. 525"
          required
        />

        <label htmlFor="currency">currency</label>
        <select name="currency" placeholder="ie. AA" required>
          <option value="USD">USD</option>
          <option value="RUB">RUB</option>
          <option value="EUR">EUR</option>
          <option value="MILES">MILES</option>
          <option value="GBP">GBP</option>
        </select>

        <label htmlFor="url">url where you found flight (optional)</label>
        <input
          type="input"
          name="url"
          placeholder="ie. https://www.expedia.com/Flight455666"
        />

        <p className={styles.desc}>
          <br />
          You can add a connecting or <br />
          return flight on the next page
        </p>

        <div className={styles.groupButton}>
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button primary>Save Flight</Button>
        </div>
      </form>
    </div>
  );
};
