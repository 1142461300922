import { firebaseConfig } from "./firebaseConfig";
import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions();
export const auth = getAuth();
