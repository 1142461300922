import { FC, ReactNode } from "react";
import styles from "./modal.module.scss";

type Props = {
  children: ReactNode;
  maxWidth?: number;
  minHeight?: number;
  withClose?: boolean;
  onCloseModal?: () => void;
};

export const Modal: FC<Props> = ({
  children,
  maxWidth = 320,
  minHeight = 370,
  withClose = true,
  onCloseModal,
}) => {
  return (
    <div className={styles.modal}>
      <div
        style={{ maxWidth: `${maxWidth}px`, minHeight: `${minHeight}px` }}
        className={styles.modalContent}
      >
        {withClose && (
          <div onClick={onCloseModal} className={styles.close}></div>
        )}
        {children}
      </div>
    </div>
  );
};
