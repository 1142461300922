import { useAppSelector } from "@app/store/hooks";
import { Header } from "@components/Header";
import styles from "./dashboard.module.scss";
import { AddFlightForm } from "@components/AddFlightForm";
import { Button } from "@components/Button";
import { ListingGridItem } from "@components/ListingGridItem";
import { SuccessFlightForm } from "@components/SuccessFlightForm";
import { Modal } from "@components/Modal";
import { FC, FormEvent, useEffect, useState, MouseEvent } from "react";
import { BsExclamationSquare } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import {
  useAddSnapMutation,
  useGetSnapCollectionQuery,
  useRemoveSnapMutation,
} from "@app/api/firebase";
import { useNavigate } from "react-router-dom";
import { LoadBar } from "@components/LoadBar";
import { ECollectionName } from "../../types";

type Props = {};

export const Dashboard: FC<Props> = (props) => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: userData } = useAppSelector((state) => state.user);
  const { data: flightsData, isLoading: isLoadingQuery } =
    useGetSnapCollectionQuery(
      {
        path: ECollectionName.flights,
        query: { where: [["uid", "==", `${userData.uid}`]] },
      },
      { skip: !userData.uid }
    );

  const [
    addDb,
    {
      isLoading: isLoadingAddMutation,
      isError: isErrorAddMutation,
      isSuccess: isSuccessAddMutation,
    },
  ] = useAddSnapMutation();

  const [removeFlight, { isLoading: isLoadingRemoveMutation }] =
    useRemoveSnapMutation();

  const [isOpenWarning, setIsOpenWarning] = useState<boolean>(false);
  const [isOpenAddFlightModal, setIsOpenAddFlightModal] =
    useState<boolean>(false);
  const [isOpenSuccessAddFlightModal, setIsOpenSuccessAddFlightModal] =
    useState<boolean>(false);
  const [isOpenErrorAddFlightModal, setIsOpenErrorAddFlightModal] =
    useState<boolean>(false);
  const [lastNewFlightId, setLastNewFlightId] = useState<string>("");
  const [checkDuplicateLastFlight, setCheckDuplicateLastFlight] =
    useState<string>("");
  const [lastDate, setLastDate] = useState<string>("");

  useEffect(() => {
    const firstEnter = localStorage.getItem("firstEnter");
    if (firstEnter !== "false") setIsOpenWarning(true);
  }, []);

  const handleCloseWarning = () => {
    localStorage.setItem("firstEnter", "false");
    setIsOpenWarning(false);
  };

  useEffect(() => {
    if (isErrorAddMutation) {
      setIsOpenErrorAddFlightModal(true);
      if (lastDate) {
        setIsOpenSuccessAddFlightModal(true);
        return;
      }
      setIsOpenAddFlightModal(true);
    }

    if (isSuccessAddMutation && lastDate) {
      setIsOpenSuccessAddFlightModal(true);
    }
  }, [isSuccessAddMutation, isErrorAddMutation, lastDate]);

  const handleGoToSavedFlight = () => {
    navigate(`/${userData.uid}\\flights\\${lastNewFlightId}`);
  };

  const handleSubmitNewFlight = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { target } = e;
    const myFormData = new FormData(target as HTMLFormElement);
    const formDataObj = Object.fromEntries(myFormData.entries());

    if (typeof formDataObj.date === "string") {
      const stringDepartDate = new Date(formDataObj.date).toDateString();
      const isDuplicate =
        checkDuplicateLastFlight ===
        `${formDataObj.airlineCode}/${formDataObj.flightNumber}/${stringDepartDate}`;
      // const isLessDate = new Date(formDataObj.date) < new Date(lastDate);

      const isAddSegment = Boolean(lastNewFlightId || "");
      const flightData = {
        parts: [
          {
            segments: [
              {
                airlineCode: formDataObj.airlineCode,
                flightNumber: formDataObj.flightNumber,
                departDate: stringDepartDate,
              },
            ],
          },
        ],
        isForm: true,
        price: formDataObj.price || "",
        currency: formDataObj.currency || "",
        url: formDataObj.url || "",
        docId: lastNewFlightId || "",
        isAddSegment,
      };
      try {
        if (isDuplicate) throw Error;
        setIsOpenAddFlightModal(false);
        setIsOpenSuccessAddFlightModal(false);
        const fetchData = await addDb({
          type: "call",
          path: ECollectionName.flights,
          function: "addFlight",
          data: flightData,
        });

        if ("data" in fetchData) {
          setLastDate(formDataObj.date);
          setLastNewFlightId(fetchData.data.data.docId);
          setCheckDuplicateLastFlight(
            `${formDataObj.airlineCode}/${formDataObj.flightNumber}/${stringDepartDate}`
          );
        }
      } catch (error) {
        setIsOpenErrorAddFlightModal(true);
      }
    }
  };

  const handleDeleteFlight = async (
    e: MouseEvent<SVGElement>,
    flightId: string,
    flightIsForm: boolean = false
  ) => {
    e.preventDefault();
    try {
      await removeFlight({
        path: `${ECollectionName.flights}.${flightId}`,
        type: "remove",
      });
      // dispatch({ type: "user/removeCommentsChannel", payload: flightId });
      if (!flightIsForm) {
        window.postMessage({ type: "removeFlight" });
      }
    } catch (error) {
      console.log("Remove flight error:", error);
    }
  };

  const handleAddFlight = () => {
    setIsOpenAddFlightModal(true);
  };

  const handleCloseErrorModal = () => {
    setIsOpenErrorAddFlightModal(false);
  };

  const handleCloseSuccessAddFlightModal = () => {
    setIsOpenSuccessAddFlightModal(false);
    resetNextFlight();
  };

  const handleCloseAddFlightModal = () => {
    setIsOpenAddFlightModal(false);
    resetNextFlight();
  };

  const resetNextFlight = () => {
    setLastNewFlightId("");
    setLastDate("");
    setCheckDuplicateLastFlight("");
  };

  return (
    <div className={styles.dashboard}>
      {!userData.uid && (
        <div className="loader-back">
          <div className="loader" />
        </div>
      )}
      <Header />
      <>
        {(isLoadingAddMutation ||
          isLoadingRemoveMutation ||
          isLoadingQuery) && <LoadBar />}
        <div className={`${styles.gridViewContainer} ${styles.mainContainer}`}>
          {isOpenWarning && (
            <div onClick={handleCloseWarning} className={styles.warning}>
              <a
                href="https://planmoretrips.com/welcome/"
                target="_blank"
                rel="noreferrer"
              >
                <Button primary>
                  <BsExclamationSquare />
                  First Time Here? Click Here For A Tutorial On How To Use
                  PlanMoreTrips.
                </Button>
              </a>
              <Button primary>
                <IoMdClose />
              </Button>
            </div>
          )}
          <h2>Your flights</h2>
        </div>
        <div className={`${styles.gridViewContainer} ${styles.mainContainer}`}>
          <button onClick={handleAddFlight} className={styles.addItem}>
            <div className={styles.addImageContainer}>
              <div className={styles.addImage} />
            </div>
            <div className={styles.textContainer}>
              <h2 className={styles.title}>Add New Flight</h2>
              <div className={styles.subtitle}>
                <p>Click above to create a new trip.</p>
              </div>
            </div>
          </button>
          {flightsData &&
            Object.values(flightsData[ECollectionName.flights])
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((flight, idx: number) => (
                <ListingGridItem
                  onDeleteFlight={handleDeleteFlight}
                  flightId={flight.id}
                  flightDate={flight.flightDate}
                  flightIsForm={flight.isForm}
                  key={idx}
                  flightName={flight.flightName}
                  flightType={flight.flightType}
                  imageId={flight.imageId}
                />
              ))}
        </div>
        {isOpenAddFlightModal && (
          <Modal
            maxWidth={500}
            minHeight={250}
            onCloseModal={handleCloseAddFlightModal}
          >
            <AddFlightForm
              onSubmitNewFlight={handleSubmitNewFlight}
              onCloseModal={handleCloseAddFlightModal}
            />
          </Modal>
        )}
        {isOpenSuccessAddFlightModal && (
          <Modal
            maxWidth={370}
            minHeight={250}
            onCloseModal={handleCloseSuccessAddFlightModal}
          >
            <SuccessFlightForm
              onSubmitNewFlight={handleSubmitNewFlight}
              onOpenSavedFlight={handleGoToSavedFlight}
            />
          </Modal>
        )}
        {isOpenErrorAddFlightModal && (
          <Modal
            maxWidth={370}
            minHeight={250}
            onCloseModal={handleCloseErrorModal}
          >
            <div className={styles.errorTitle}>
              Flight not added, please check that the data is correct.
            </div>
          </Modal>
        )}
      </>
    </div>
  );
};
