import { Header } from "@components/Header";
import { FC, useEffect, useState } from "react";
import styles from "./share.module.scss";
import { Button } from "@components/Button";
import { RiShareBoxFill } from "react-icons/ri";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineTemplate } from "react-icons/hi";
import { Comments } from "@components/Comments";
import { FlightOption } from "@components/FlightOption";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSnapCollectionQuery } from "@app/api/firebase";
import { ECollectionName, IBookingOption } from "../../types";
import { LoadBar } from "@components/LoadBar";
import { useAppSelector } from "@app/store/hooks";

type Props = {};

enum EMobileTabType {
  gallery = "gallery",
  message = "message",
}

enum EMenuTabType {
  flight = "flight",
  lodging = "lodging",
  transport = "transport",
  activities = "activities",
}

enum EStatusSearchPrice {
  pending = "pending",
  success = "success",
  read = "read",
}

export const Share: FC<Props> = (props) => {
  const { uid } = useAppSelector((state) => state.user.data);
  const { flight: flightId } = useParams<{ user: string; flight: string }>();
  const navigate = useNavigate();

  const {
    dataFlight,
    isLoading,
    isSuccess: isSuccessQuery,
  } = useGetSnapCollectionQuery(
    {
      path: `${ECollectionName.flights}.${flightId}`,
    },
    {
      selectFromResult: ({ data, error, isLoading, isSuccess }) => {
        return {
          dataFlight: data?.[ECollectionName.flights]?.[flightId!],
          error,
          isLoading,
          isSuccess,
        };
      },
    }
  );

  const [mobileTab, setMobileTab] = useState<EMobileTabType>(
    EMobileTabType.gallery
  );
  const [menuTab, setMenuTab] = useState<EMenuTabType>(EMenuTabType.flight);

  const handleChangeMobileTabItem = (tab: EMobileTabType) => setMobileTab(tab);

  const handleChangeMainTabItem = (tab: EMenuTabType) => setMenuTab(tab);

  const isOpenBookingOption =
    dataFlight?.statusSearchPrice === EStatusSearchPrice.success ||
    dataFlight?.statusSearchPrice === EStatusSearchPrice.read;

  useEffect(() => {
    if (uid && !dataFlight && isSuccessQuery) {
      navigate(`/${uid}/flights`);
    }
  }, [uid, dataFlight, isSuccessQuery, navigate]);

  return (
    <>
      {isLoading && <LoadBar />}
      <Header />
      {dataFlight && (
        <div className={styles.flightWrapper}>
          <div className={styles.flightContainer}>
            <div
              className={`${styles.scrollWrapper} ${
                mobileTab === EMobileTabType.message && styles.hideFlight
              }`}
            >
              <div className={styles.flightHeader}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url("/assets/flight/${dataFlight.imageId}.jpg")`,
                  }}
                >
                  <h1 className={styles.headline}>{dataFlight.flightName}</h1>
                </div>
                <div className={styles.headerMenu}>
                  <div className={styles.tabGroup}>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.flight)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.flight && styles.activeTab
                      }`}
                    >
                      Flights
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.lodging)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.lodging && styles.activeTab
                      }`}
                    >
                      Lodging
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.transport)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.transport && styles.activeTab
                      }`}
                    >
                      Transport
                    </span>
                    <span
                      onClick={() =>
                        handleChangeMainTabItem(EMenuTabType.activities)
                      }
                      className={`${styles.tabItem} ${
                        menuTab === EMenuTabType.activities && styles.activeTab
                      }`}
                    >
                      Activities
                    </span>
                  </div>
                </div>
              </div>

              {menuTab !== EMenuTabType.flight && (
                <div className={styles.inDevelopingWrapper}>
                  <div>
                    This feature is currently under development. It will be
                    available soon.
                  </div>
                </div>
              )}

              {menuTab === EMenuTabType.flight && (
                <>
                  {isOpenBookingOption && (
                    <>
                      <h2>Lowest Prices For Your Flight</h2>
                      {dataFlight.bookingOption ? (
                        dataFlight.bookingOption.map(
                          (option: IBookingOption, idx: number) => (
                            <div
                              key={idx}
                              className={styles.lowestPricesContainer}
                            >
                              <a
                                target={"_blank"}
                                href={option.url}
                                rel="noreferrer"
                              >
                                <div className={styles.lowestPricesItem}>
                                  <span className={styles.lowestPricesTitle}>
                                    Book with {option.labelFlightCompany}
                                  </span>
                                  <Button>{option.price}</Button>
                                </div>
                              </a>
                            </div>
                          )
                        )
                      ) : (
                        <div className={styles.lowestPricesContainer}>
                          <div className={styles.lowestPricesItem}>
                            Price not found
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {menuTab === EMenuTabType.flight && (
                    <>
                      <div className={styles.headlineMain}>
                        <div className={styles.optionMenu}>
                          <h2>Your Saved Flight</h2>
                          <a
                            target="_blank"
                            href={dataFlight.url}
                            rel="noreferrer"
                          >
                            <RiShareBoxFill />
                          </a>
                        </div>
                      </div>
                      <div className={styles.flightMain}>
                        <FlightOption />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div
              className={`${styles.commentsContainer} ${
                mobileTab === EMobileTabType.message && styles.showMessage
              }`}
            >
              <Comments />
            </div>
            <div className={styles.mobileNavigation}>
              <span
                onClick={() =>
                  handleChangeMobileTabItem(EMobileTabType.gallery)
                }
                className={`${styles.tabItem} ${
                  mobileTab === EMobileTabType.gallery && styles.activeTab
                }`}
              >
                <span>
                  <HiOutlineTemplate />
                </span>
              </span>
              <span
                onClick={() =>
                  handleChangeMobileTabItem(EMobileTabType.message)
                }
                className={`${styles.tabItem} ${
                  mobileTab === EMobileTabType.message && styles.activeTab
                }`}
              >
                <span>
                  <AiOutlineMessage />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
