import { useAppSelector } from "@app/store/hooks";
import { DropdownMenu } from "@components/DropdownMenu";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import styles from "./header.module.scss";

type Props = {};

export const Header: FC<Props> = (props) => {
  const { data: userData } = useAppSelector((store) => store.user);
  const [toggleDropdownMenu, setToggleDropdownMenu] = useState<boolean>(false);
  const handleToggleDropdownMenu = (): void => {
    setToggleDropdownMenu((toggleDropdownMenu) => !toggleDropdownMenu);
  };

  const handleClickOutside = () => {
    setToggleDropdownMenu(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.logoWrapper}>
        <div className={styles.logo}>
          <Link to={userData.uid ? `/${userData.uid}\\flights` : "\\login"} />
        </div>
      </div>
      <div className={styles.menu}>
        <Link to={userData.uid ? `/${userData.uid}\\flights` : "\\login"}>
          <div className={styles.homeIcon}></div>
        </Link>
        <img
          className={styles.avatarIcon}
          src={userData.photoURL || "/assets/user.png"}
          alt="user avatar"
        />
        <div
          ref={ref}
          onClick={handleToggleDropdownMenu}
          className={styles.dropdownArrowIcon}
        >
          {toggleDropdownMenu && <DropdownMenu />}
        </div>
      </div>
    </header>
  );
};
