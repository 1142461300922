import { FC, ReactNode, MouseEvent } from "react";
import styles from "./button.module.scss";

type Props = {
  children: string | ReactNode;
  primary?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const Button: FC<Props> = ({
  children,
  primary = false,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${primary ? styles.primary : ""} ${
        styles.buttonContainer
      } ${className}`}
    >
      <span className={styles.label}>{children}</span>
    </button>
  );
};
