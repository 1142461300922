import { FC } from "react";
import styles from "./priceSearchBlock.module.scss";
import { BsCashCoin, BsClock, BsMailbox } from "react-icons/bs";

type Props = {
  status: "pending" | "success" | "read";
};

export const PriceSearchBlock: FC<Props> = ({ status }) => {
  return (
    <div className={styles.container}>
      {status === "pending" ? (
        <>
          <BsCashCoin />
          <p>
            Your flight has been saved and we are currently cross-checking the
            price across 500+ other travel sites around the world.
          </p>
          <BsClock />
          <p>This process typically takes up to 5 minutes.</p>
          <BsMailbox />
          <p>We will email you when the results are ready.</p>
        </>
      ) : (
        <>
          <span>&#127881;</span>
          <p>Good news! Your results are in.</p>
          <span>&#128270;</span>
          <p>
            We searched 500+ travel sites in 75+ countries and you currently
            have the cheapest price available.
          </p>
          <span>&#129297;</span>
          <p>
            Prices can change day to day, so we will continue to monitor the
            prices and let you know of any changes via email.
          </p>
        </>
      )}
    </div>
  );
};
