import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  data: {
    email: string;
    uid: string;
    displayName: string;
    photoURL: string;
    commentsChannel: string[];
  };
  isLoading: boolean;
  isError: boolean;
}

const initialState: IUserState = {
  data: {
    email: "",
    uid: "",
    displayName: "",
    photoURL: "",
    commentsChannel: [],
  },
  isLoading: false,
  isError: false,
};

const isLoading = createAction("user/isLoading");
const error = createAction("user/error");
const email = createAction("user/changeEmail");
const uid = createAction("user/changeUid");
const displayName = createAction("user/changeDisplayName");
const photoURL = createAction("user/changePhotoURL");
const addCommentsChannel = createAction("user/addCommentsChannel");
// const removeCommentsChannel = createAction("user/removeCommentsChannel");

const userReducer = createReducer(initialState, {
  [isLoading.type]: (state, action: PayloadAction<boolean>) => {
    state.isLoading = action.payload;
  },
  [error.type]: (state, action: PayloadAction<boolean>) => {
    state.isError = action.payload;
  },
  [email.type]: (state, action: PayloadAction<string>) => {
    state.data.email = action.payload;
  },
  [uid.type]: (state, action: PayloadAction<string>) => {
    state.data.uid = action.payload;
  },
  [displayName.type]: (state, action: PayloadAction<string>) => {
    state.data.displayName = action.payload;
  },
  [photoURL.type]: (state, action: PayloadAction<string>) => {
    state.data.photoURL = action.payload;
  },
  [addCommentsChannel.type]: (
    state,
    action: PayloadAction<string> | PayloadAction<string[]>
  ) => {
    if (action.payload) {
      if (Array.isArray(action.payload)) {
        state.data.commentsChannel = action.payload;
        return;
      }
      state.data.commentsChannel.push(action.payload);
    }
  },
  // [removeCommentsChannel.type]: (
  //   state,
  //   action: PayloadAction<string> | PayloadAction<string[]>
  // ) => {
  //   if (action.payload) {
  //     state.data.commentsChannel = state.data.commentsChannel.filter(
  //       (id) => id !== action.payload
  //     );
  //   }
  // },
});

export default userReducer;
