import { useAppSelector } from "@app/store/hooks";
import { Button } from "@components/Button";
import { FC, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "./shareBlock.module.scss";

type Props = {
  onClose: () => void;
};

export const ShareBlock: FC<Props> = ({ onClose }) => {
  const params = useParams();
  const {
    data: { uid: userId },
  } = useAppSelector((state) => state.user);
  const [shareLocation, setShareLocation] = useState("");
  const shareUrl = useMemo(() => {
    const location = window.location;
    return `${location.protocol}//${location.host}/${userId}/share/${params.flight}`;
  }, [params, userId]);

  useEffect(() => {
    setShareLocation(shareUrl);
  }, [shareUrl]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLocation);
    onClose();
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Share this itinerary with your travel partners by using the following
        view-only link.
      </h2>
      <input
        className={styles.input}
        type="text"
        value={shareLocation}
        onChange={(e) => setShareLocation(e.target.value)}
      />
      <div className={styles.buttonGroup}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCopyLink} primary>
          Copy Link
        </Button>
      </div>
    </div>
  );
};
