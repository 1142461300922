import { FC, useState, MouseEvent, FormEvent, useEffect } from "react";
import styles from "./auth.module.scss";
import { Button } from "@components/Button";
import { AnimatedBg } from "@components/AnimatedBg";
import { BsGoogle } from "react-icons/bs";
import { useAppDispatch } from "@app/store/hooks";

type Props = {};

enum ETypeSignIn {
  google = "google",
  email = "email",
  successEmail = "successEmail",
}

export const Auth: FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const [typeSignIn, setTypeSignIn] = useState<string>(ETypeSignIn.google);
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    dispatch({ type: "auth/SIGN_IN_WITH_LINK" });
  }, [dispatch]);

  const handleChangeTypeSignIn = (
    type: ETypeSignIn,
    e: MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setTypeSignIn(type);
  };

  const handleInputEmail = (targetValue: string) => {
    setEmail(targetValue);
  };

  const handleRegisterUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: "auth/SEND_SIGN_IN_LINK", payload: email });
    setTypeSignIn(ETypeSignIn.successEmail);
  };

  return (
    <div className={styles.pageWrapper}>
      <AnimatedBg />

      <div className={styles.widgetWrapper}>
        <div className={styles.authLogo} />
        <div className={styles.widgetContent}>
          {typeSignIn === ETypeSignIn.google && (
            <>
              <h2 className={styles.headline}>Sign Up / Login Below</h2>
              <div className={styles.formInputGroup}>
                <Button
                  primary
                  className={styles.wrapper}
                  onClick={() => {
                    dispatch({ type: "auth/SIGN_IN_GOOGLE" });
                  }}
                >
                  {" "}
                  <BsGoogle /> <span>Sign in with Google</span>
                </Button>
                <p className={styles.formInputGroup__subtitle}>
                  <span>Don't have a Google account? </span>
                  <a
                    onClick={(e) =>
                      handleChangeTypeSignIn(ETypeSignIn.email, e)
                    }
                    href="/#"
                  >
                    Click here
                  </a>
                </p>
              </div>
              <div className={styles.footer}>
                <span className={styles.text}>
                  By signing up, you agree to our{" "}
                </span>
                <span className={styles.text}>
                  <a
                    target="_blank"
                    href="https://planmoretrips.com/terms-of-service"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://planmoretrips.com/privacy-policy"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
            </>
          )}

          {typeSignIn === ETypeSignIn.email && (
            <>
              <h2 className={styles.headline}>Sign Up / Login Below</h2>
              <div className={styles.formInputGroup}>
                <form
                  className={styles.inputContainer}
                  onSubmit={handleRegisterUser}
                >
                  <label>E-mail Address</label>
                  <input
                    autoFocus={false}
                    onChange={(e) => handleInputEmail(e.target.value)}
                    value={email}
                    name="emailAddress"
                    placeholder="your@email.com"
                    type="email"
                  />
                  <Button primary className={styles.wrapper}>
                    Enter PlanMoreTrips
                  </Button>
                </form>
                <p className={styles.formInputGroup__subtitle}>
                  Want to use Google Account?{" "}
                  <a
                    onClick={(e) =>
                      handleChangeTypeSignIn(ETypeSignIn.google, e)
                    }
                    href="/#"
                  >
                    Click here
                  </a>
                </p>
              </div>
              <div className={styles.footer}>
                <p>
                  For extra security, we always email you a new login link so
                  you don’t ever have to remember a password!
                </p>
              </div>
            </>
          )}

          {typeSignIn === ETypeSignIn.successEmail && (
            <div className={styles.successEmailInfo}>
              <h2>
                <span className={styles.info}>
                  Please check your email{" "}
                  <img src={"/assets/mailbox.png"} alt="mailbox" />
                </span>
              </h2>
              <h2 className={styles.magicLink}>
                <span className={styles.info}>
                  {" "}
                  We sent you a magic link to instantly{" "}
                </span>
                <span className={styles.info}>
                  {" "}
                  sign you into PlanMoreTrips
                  <img src={"/assets/link.png"} alt="link" />{" "}
                </span>
              </h2>
              <h2 className={styles.spam}>
                <span className={styles.info}>
                  If you don't see it, check your{" "}
                </span>{" "}
                <span className={styles.info}>
                  spam or promotions tab{" "}
                  <img src={"/assets/alarm.png"} alt="alarm" />{" "}
                </span>
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
