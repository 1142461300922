import { useRef, useEffect, useCallback } from "react";

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (event.target) {
        if (ref.current && !(ref.current as any).contains(event.target)) {
          callback();
        }
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [handleClick]);

  return ref;
};
