import { FC, useEffect, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "./settings.module.scss";
import { Button } from "@components/Button";
import { Header } from "@components/Header";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";

type Props = {};

export const Settings: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { data: userData } = useAppSelector((state) => state.user);

  const [isOpenChangePhotoBlock, setIsOpenChangePhotoBlock] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isChangeDisplayName, setIsChangeDisplayName] =
    useState<boolean>(false);
  const [isChangeEmail, setIsChangeEmail] = useState<boolean>(false);

  useEffect(() => {
    const { email, displayName } = userData;
    const firstName = displayName?.split(" ")[0];
    const lastName = displayName?.split(" ")[1];
    setEmail(email);
    setFirstName(firstName);
    setLastName(lastName);
  }, [userData]);

  const handleChangePhotoBlock = () => {
    setIsOpenChangePhotoBlock(
      (isOpenChangePhotoBlock) => !isOpenChangePhotoBlock
    );
  };

  const handleEditUserData = () => {
    if (firstName && lastName && email) {
      if (isChangeDisplayName) {
        dispatch({
          type: "auth/CHANGE_USER_NAME",
          payload: `${firstName} ${lastName}`,
        });
        setIsChangeDisplayName(false);
      }
      if (isChangeEmail) {
        dispatch({
          type: "auth/CHANGE_USER_EMAIL",
          payload: email,
        });
        setIsChangeEmail(false);
      }
    }
  };

  const handleEditUserAvatar = () => {
    if (acceptedFiles[0]) {
      dispatch({
        type: "auth/CHANGE_USER_PHOTO",
        payload: acceptedFiles[0],
      });
      setIsOpenChangePhotoBlock(false);
    }
  };

  const handleChangeFirstName = (targetValue: string) => {
    setIsChangeDisplayName(true);
    setFirstName(targetValue);
  };

  const handleChangeLastName = (targetValue: string) => {
    setIsChangeDisplayName(true);
    setLastName(targetValue);
  };

  const handleChangeEmail = (targetValue: string) => {
    setIsChangeEmail(true);
    setEmail(targetValue);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });
  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      {!userData.uid && (
        <div className="loader-back">
          <div className="loader" />
        </div>
      )}
      <Header />
      <div className={styles.splitWrapper}>
        <div className={styles.splitContent}>
          <div className={styles.subNavWidget}>
            <div
              className={styles.userImage}
              onClick={handleChangePhotoBlock}
              style={{
                backgroundImage: `url(${
                  userData.photoURL || "/assets/user-settings.png"
                })`,
                backgroundSize: `${userData.photoURL ? "cover" : "contain"}`,
              }}
            >
              <button className={styles.userBtn}>
                <span>Edit Cover</span>
                <AiOutlineCamera className={styles.cameraBgIcon} />
              </button>
            </div>
            <div className={styles.userMeta}>
              <h2>{userData.displayName}</h2>
              <p>
                <span>{userData.email}</span>
              </p>
            </div>
          </div>
          <div className={styles.formWidgetContainer}>
            {isOpenChangePhotoBlock && (
              <div className={styles.formWidget}>
                <div className={styles.basicInfo}>
                  <h3>Change Photo</h3>
                </div>
                <div>
                  <section className="container">
                    <div {...getRootProps({ className: styles.dropZone })}>
                      <input accept="image/*" {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      <ul>{files}</ul>
                    </div>
                    <aside></aside>
                  </section>
                </div>
                <div className={styles.btnMenu}>
                  <Button onClick={handleEditUserAvatar} primary>
                    Save
                  </Button>
                </div>
              </div>
            )}

            <div className={styles.formWidget}>
              <div className={styles.basicInfo}>
                <h3>Profile Settings</h3>
                <p>You can manage your personal information.</p>
              </div>
              <div className={styles.editUserInfo}>
                <div className={styles.inputContainer}>
                  <label>First Name</label>
                  <input
                    name="firstName"
                    value={firstName}
                    onChange={(e) => handleChangeFirstName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label>Last Name</label>
                  <input
                    name="lastName"
                    value={lastName}
                    onChange={(e) => handleChangeLastName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label>Email Address</label>
                  <input
                    name="emailAddress"
                    value={email}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.btnMenu}>
                <Button primary onClick={handleEditUserData}>
                  Save Settings
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
