import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { firebaseApi } from "@api/firebase";
import { createFirebaseAuthMiddleware } from "@store/middleware/firebase";
import userReducer from "@app/store/reducers/user";

const firebaseAuthMiddleware = createFirebaseAuthMiddleware();

export const store = configureStore({
  reducer: {
    user: userReducer,
    [firebaseApi.reducerPath]: firebaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(firebaseApi.middleware)
      .concat(firebaseAuthMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
