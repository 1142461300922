import { FC, useEffect, useState } from "react";

type Props = {};

export const AnimatedBg: FC<Props> = () => {
  const [imageObj, setImageObj] = useState({
    image: 20,
    prevImage: 19,
  });
  const { image, prevImage } = imageObj;

  useEffect(() => {
    const interval = setInterval(() => {
      setImageObj(({ image, prevImage }) => {
        if (prevImage > image || (prevImage === 1 && image === 20)) {
          if (prevImage !== 1) {
            return { prevImage: prevImage - 2, image };
          } else {
            return { prevImage: 19, image };
          }
        } else {
          if (image !== 2) {
            return { image: image - 2, prevImage };
          } else {
            return { image: 20, prevImage };
          }
        }
      });
    }, 14000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        className={prevImage < image ? "backImg" : "backImg hidden"}
        key={prevImage}
        style={{
          backgroundImage: `url("/assets/login/${prevImage}.jpg")`,
          display: "block",
          zIndex:
            prevImage > image || (prevImage === 1 && image === 20) ? "1" : "0",
        }}
      />
      <div
        className={prevImage > image ? "backImg" : "backImg hidden"}
        key={image}
        style={{
          backgroundImage: `url("/assets/login/${image}.jpg")`,
          display: "block",
          zIndex:
            prevImage > image || (prevImage === 1 && image === 20) ? "0" : "1",
        }}
      />
    </>
  );
};
