import { Button } from "@components/Button";
import { FC, FormEvent } from "react";
import styles from "./successFlightForm.module.scss";

type Props = {
  onOpenSavedFlight: () => void;
  onSubmitNewFlight?: (e: FormEvent<HTMLFormElement>) => void;
};

export const SuccessFlightForm: FC<Props> = ({
  onOpenSavedFlight,
  onSubmitNewFlight,
}) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Your flight has been saved.</h2>
      <p className={styles.subtitle}>
        You can add a connecting or return flight by clicking the below button.
      </p>
      <form action="" onSubmit={onSubmitNewFlight}>
        <label htmlFor="date">departure date</label>
        <input type="date" id="date" name="date" required />

        <label htmlFor="airline-code">airline code</label>
        <input type="input" name="airlineCode" placeholder="ie. AA" required />

        <label htmlFor="flight-number">flight number</label>
        <input type="input" name="flightNumber" placeholder="ie. 78" required />
        <Button primary>Add Flight</Button>
      </form>
      <div className={styles.groupButton}>
        <Button onClick={onOpenSavedFlight}>
          <span>View Saved Flight</span>
        </Button>
      </div>
    </div>
  );
};
