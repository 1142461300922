export const firebaseConfig = {
  apiKey: "AIzaSyB6JEV6DaSlSQGpVZkKnTn8yQUSIKq7QYo",
  authDomain: "app.planmoretrips.com",
  databaseURL: "https://planaway-7fc74.firebaseio.com",
  projectId: "planaway-7fc74",
  storageBucket: "planaway-7fc74.appspot.com",
  messagingSenderId: "96594801878",
  appId: "1:96594801878:web:0be7e24ee0bcb7a3",
  measurementId: "G-C29DGTYBES",
};
