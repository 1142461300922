import {
  useAddSnapMutation,
  useGetSnapCollectionQuery,
  useLazyGetSnapCollectionQuery,
} from "@app/api/firebase";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { LoadBar } from "@components/LoadBar";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ECollectionName } from "../../types";
import styles from "./comments.module.scss";

type Props = {};

export const Comments: FC<Props> = (props) => {
  const { flight: flightId } = useParams<{ user: string; flight: string }>();

  const { data: commentsData } = useGetSnapCollectionQuery({
    path: ECollectionName.comments,
    query: { where: [["flightId", "==", `${flightId}`]] },
  });
  const { data: usersData } = useGetSnapCollectionQuery({
    path: ECollectionName.users,
    query: { where: [["commentsChannel", "array-contains", `${flightId}`]] },
  });
  const [getSnapCollection] = useLazyGetSnapCollectionQuery();
  const [addDb, { isLoading: isLoadingMutation }] = useAddSnapMutation();

  const { data: currentUser } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [textComment, setTextComment] = useState<string>();
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        handleSendComment();
      }
    }
  };

  const handleSendComment = async () => {
    const createdAt = `${new Date().toDateString()}, ${new Date().toLocaleTimeString(
      "en-GB"
    )}`;

    const commentData = {
      email: currentUser.email,
      text: textComment,
      uid: currentUser.uid,
      photoURL: currentUser.photoURL,
      createdAt,
      flightId,
    };
    if (flightId && textComment) {
      try {
        await addDb({
          type: "set",
          path: ECollectionName.comments,
          data: commentData,
        });
      } catch (error) {
        console.log(error);
      }
      addIdFlightToCommentsChannel(flightId);
      setTextComment("");
    }
  };

  const addIdFlightToCommentsChannel = (flightId: string) => {
    const hasFlightId = currentUser.commentsChannel.includes(flightId);
    if (!hasFlightId) {
      getSnapCollection({
        path: ECollectionName.users,
        query: {
          where: [["commentsChannel", "array-contains", `${flightId}`]],
        },
      });
      dispatch({ type: "user/addCommentsChannel", payload: flightId });
      dispatch({
        type: "auth/UPDATE_USER",
      });
    }
  };

  const handleChangeText = (textValue: string) => {
    setTextComment(textValue);
  };

  return (
    <>
      {isLoadingMutation && <LoadBar />}
      <div className={styles.commentsContent}>
        <div className={styles.title}>Flight Comment</div>
        <div className={styles.messageList}>
          {commentsData?.comments &&
            usersData?.[ECollectionName.users] &&
            Object.values(commentsData?.comments)
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((comment, idx) => (
                <div key={idx} className={styles.messageContainer}>
                  <div className={styles.messageContent}>
                    <div className={styles.avatar}>
                      <img
                        src={
                          usersData?.[ECollectionName.users][comment.uid]
                            ?.photoURL || comment.photoURL
                        }
                        alt="avatar"
                      />
                    </div>
                    <div className={styles.info}>
                      <span className={styles.email}>
                        {usersData?.[ECollectionName.users][comment.uid]
                          ?.displayName || comment.email}
                      </span>
                      <p className={styles.text}>{comment.text}</p>
                      <p className={styles.date}>{comment.createdAt}</p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div className={styles.commentArea}>
          {currentUser.uid ? (
            <>
              <div className={styles.avatar}>
                <img
                  src={currentUser.photoURL || "/assets/user.png"}
                  alt="user avatar"
                />
              </div>
              <textarea
                onKeyDown={handleKeyDown}
                onChange={(e) => handleChangeText(e.target.value)}
                name="comment"
                value={textComment}
                placeholder="Write a comment..."
                rows={5}
              ></textarea>
            </>
          ) : (
            <p>{<Link to="/login">Login</Link>} to leave a comment.</p>
          )}
        </div>
      </div>
    </>
  );
};
