import { FC } from "react";
import styles from "./flightOption.module.scss";
import { FlightSegment } from "@components/FlightSegment";
import { useParams } from "react-router-dom";
import { useGetSnapCollectionQuery } from "@app/api/firebase";
import { ECollectionName, IPart, ISegment } from "../../types";

type Props = {};

export const FlightOption: FC<Props> = (props) => {
  const { flight } = useParams<{ user: string; flight: string }>();

  const { dataParts } = useGetSnapCollectionQuery(
    {
      path: `${ECollectionName.flights}.${flight}`,
    },
    {
      selectFromResult: ({ data, error, isLoading }) => {
        return {
          dataParts: data?.[ECollectionName.flights]?.[flight!].parts,
          error,
          isLoading,
        };
      },
    }
  );
  return (
    <>
      {dataParts &&
        dataParts.map((part: IPart, partId: number) => (
          <div key={partId} className={styles.optionInfoContainer}>
            <div className={styles.title}>
              <h3>
                <div
                  className={
                    partId === 0
                      ? styles.iconDepartFlight
                      : styles.iconReturnFlight
                  }
                ></div>
                <span className={`${styles.part} ${styles.optionLink}`}>
                  {partId === 0 ? `Departing flight` : `Returning flight`}
                </span>
                <span className={styles.date}>
                  {part.segments[0].departDate}
                </span>
              </h3>
              <div className={styles.optionContainer}>
                {part.stops >= 1 && (
                  <div className={styles.optionStops}>
                    <span>{part.stops}</span>
                    <span>Stops</span>
                  </div>
                )}
                <div className={styles.optionTime}>
                  <span>{part.totalFlightTime}</span>
                  <span>Total flight time</span>
                </div>
              </div>
            </div>
            {part.segments.map((segment: ISegment, idx: number) => (
              <FlightSegment partId={partId} segmentId={idx} key={idx} />
            ))}
          </div>
        ))}
    </>
  );
};
