import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./dropdownMenu.module.scss";

type Props = {};

export const DropdownMenu: FC<Props> = (props) => {
  const { data: userData } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  const handleSignOut = () => {
    dispatch({ type: "auth/SIGN_OUT" });
  };

  return (
    <div className={styles.dropdownMenu}>
      {userData.uid ? (
        <>
          <Link
            to={`/${userData.uid}\\flights`}
            className={styles.dropdownItem}
          >
            flights
          </Link>
          <Link
            to={`/${userData.uid}\\settings`}
            className={styles.dropdownItem}
          >
            account settings
          </Link>
          <Link to={`/login`}>
            <span className={styles.dropdownItem} onClick={handleSignOut}>
              sign out
            </span>
          </Link>
        </>
      ) : (
        <Link to={"/login"}>
          <span className={styles.dropdownItem}>sign in</span>
        </Link>
      )}
    </div>
  );
};
