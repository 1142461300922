import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "@containers/Dashboard";
import { Auth } from "@containers/Auth";
import { Settings } from "@containers/Settings";
import { Flight } from "@containers/Flight";
import { useAppSelector } from "@app/store/hooks";
import { Share } from "@containers/Share";
import { useMemo } from "react";

function App() {
  const {
    isLoading,
    data: { uid },
  } = useAppSelector((state) => state.user);
  const localUID = localStorage.uid;
  const isAuth = useMemo(() => localUID || uid, [uid, localUID]);
  return (
    <div className="App">
      {isLoading && (
        <div className="loader-back">
          <div className="loader" />
        </div>
      )}
      <BrowserRouter>
        <Routes>
          {isAuth ? (
            <>
              <Route
                path="*"
                element={<Navigate to={`${localUID || uid}/flights`} replace />}
              />
              <Route path="/:user/share/:flight" element={<Share />} />
              <Route
                path={`${localUID || uid}/flights`}
                element={<Dashboard />}
              />
              <Route
                path={`${localUID || uid}/settings`}
                element={<Settings />}
              />
              <Route
                path={`${localUID || uid}/flights/:flight`}
                element={<Flight />}
              />
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/:user/share/:flight" element={<Share />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
