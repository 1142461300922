export const DELAY_BOOKING_OPTION = 1;

export interface IFlight {
  airlineCode: string;
  currency: string;
  date: string;
  flightNumber: string;
  id: string;
  url: string;
  source: Object;
}

export enum ECollectionName {
  flights = "flights",
  users = "users",
  comments = "comments",
}

export enum EUserActions {
  isLoading = "user/isLoading",
  error = "user/error",
  email = "user/changeEmail",
  uid = "user/changeUid",
  displayName = "user/changeDisplayName",
  photoURL = "user/changePhotoURL",
}

export interface IBookingOption {
  url: string;
  labelFlightCompany: string;
  price: string;
}

export interface ISegment {
  airlineCode: string;
  airlineName: string;
  arrivalAirportName: string;
  arrivalAirportShort: string;
  arrivalCity: string;
  arrivalDate: string;
  arrivalTime: string;
  arrivalUtcOffsetHours: number;
  depart: string;
  departAirportName: string;
  departAirportShort: string;
  departCity: string;
  departDate: string;
  departTime: string;
  departUtcOffsetHours: number;
  flightNumber: string;
  flightTime: string;
  planeType: string;
  serviceClassPlane: string;
  travelTime: string;
  travelTimeMs: number;
  source: unknown;
}

export interface IPart {
  stops: number;
  totalFlightTime: string;
  segments: ISegment[];
}
export interface IFlightData {
  currency: string;
  flightName: string;
  flightType: string;
  id: string;
  price: string;
  statusSearchPrice: string;
  uid: string;
  url: string;
  bookingOption: IBookingOption[];
  parts: IPart[];
  flightDate: { [key: string]: string };
}

export enum EMobileTabType {
  gallery = "gallery",
  message = "message",
}

export enum EMenuTabType {
  flight = "flight",
  lodging = "lodging",
  transport = "transport",
  activities = "activities",
}

export enum EStatusSearchPrice {
  pending = "pending",
  success = "success",
  read = "read",
}
